'use client';

import { Flex, FlexProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface PageContainerProps extends FlexProps {}
const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
  ({ children, ...flexProps }, ref) => {
    return (
      <Flex flexDir="column" alignItems="center" w="100%">
        <Flex
          flexDir="column"
          alignItems="center"
          py={{ base: '0.5rem', md: '1rem', '2xl': '1.5rem' }}
          px={{ base: '0.5rem', md: '2rem' }}
          mb="1.75rem"
          w="100%"
          maxW={{ base: '100vw', '2xl': '1760px' }}
          gap={{ base: '0.5rem', xl: '1rem', '2xl': '1.5rem' }}
          {...flexProps}
        >
          {children}
        </Flex>
      </Flex>
    );
  }
);

PageContainer.displayName = 'PageContainer';

export default PageContainer;
